define("Automatons/automatons/2001", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2001,
    type: "automatons",
    attributes: {
      name: "VACU PostChatSurvey",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-2698",
      initialNode: "node::2001",
      style: "style::2004"
    }
  };
});