define("Automatons/nodes/2004", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2004,
    type: "nodes",
    attributes: {
      name: "VACU - AP Form - Authentication - Datapass",
      type: "datapass",
      isExitNode: 1,
      isOutcomeNode: 1,
      onExit: function onExit() {
        var formData = api.getAllFormData();
        api.IJSF.sendDatapassMessage(formData);
      }
    }
  };
});