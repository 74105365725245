define("Automatons/automatons/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "automatons",
    attributes: {
      name: "VACU - AP Form - Authentication",
      ariaEnabled: true,
      description: "CMRGEN-26842",
      initialNode: "node::2003",
      style: "style::2005"
    }
  };
});