define("Automatons/nodes/2005", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2005,
    type: "nodes",
    attributes: {
      name: "VACU - AP Form - Authentication - Cancel",
      type: "datapass",
      isExitNode: 1,
      isOutcomeNode: 1,
      onExit: function onExit() {
        api.IJSF.sendDatapassMessage('User canceled form');
      }
    }
  };
});