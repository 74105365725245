define("Automatons/nodes/2003", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2003,
    type: "nodes",
    attributes: {
      name: "VACU - AP Form - Authentication - Initial",
      type: "agent-pushed",
      formType: "postchat",
      header: "Please enter the following information:",
      inputs: [{
        id: "Full_Name",
        type: "input",
        dataErrorMesssage: "Please enter your full name",
        isRequired: true,
        label: "Full Name",
        requiredErrorMesssage: "Please enter your full name",
        size: "flex-100"
      }, {
        id: "Member_Number",
        type: "input",
        dataErrorMesssage: "Please enter your member number",
        isRequired: true,
        label: "Member Number",
        mask: true,
        maxlength: 8,
        pattern: "^[0-9]{4,8}$",
        requiredErrorMesssage: "Please enter your member number",
        size: "flex-100"
      }, {
        id: "Date_Of_Birth",
        type: "input",
        dataErrorMesssage: "Please enter your date of birth (MM/DD/YYYY)",
        isRequired: true,
        label: "DOB (MM/DD/YYYY)",
        maxlength: 14,
        pattern: "^(0[1-9]|1[012])/(0[1-9]|[12][0-9]|3[01])/(19|20)\\d\\d$",
        requiredErrorMesssage: "Please enter your date of birth (MM/DD/YYYY)",
        size: "flex-100"
      }, {
        id: "SSN_Last_4_Digits",
        type: "input",
        dataErrorMesssage: "Please enter the last 4 digits of your SSN",
        display_masked: true,
        isRequired: true,
        label: "SSN - Last 4 Digits",
        mask: true,
        maxlength: 4,
        pattern: "^[0-9]{4}$",
        requiredErrorMesssage: "Please enter the last 4 digits of your SSN",
        size: "flex-100"
      }, {
        id: "Cell_Phone_Number",
        type: "input",
        dataErrorMesssage: "Please enter your cell phone number",
        isRequired: true,
        label: "Cell Phone Number",
        maxlength: 14,
        pattern: "^(\\(?\\d{3}\\)?(\\s|-)?\\d{3}(\\s|-)?\\d{4})$",
        requiredErrorMesssage: "Please enter your cell phone number",
        size: "flex-100"
      }],
      onEntry: function onEntry() {
        setTimeout(checkFields, 300);

        function checkFields() {
          var $viewport = api.automaton.Automaton.View.$viewport;
          var isWebkit = navigator.userAgent.indexOf('AppleWebKit') != -1 && navigator.userAgent.indexOf('Edge') == -1;

          $('input[name="SSN_Last_4_Digits"]', $viewport).bind("input", function () {
            if (!isWebkit) {
              var text = $(this).val();
              var replace_text = text.replace(/./g, "•");
              $(this).next('[class*="obscure-input"]', $viewport).text(replace_text);
            }
          });
        }
      },
      submit_btn: "Submit",
      template: "template::2004",
      transitions: {
        next: [{
          target: "node::2004"
        }]
      }
    }
  };
});