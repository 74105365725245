define("Automatons/nodes/2001", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2001,
    type: "nodes",
    attributes: {
      name: "VACU PostChatSurvey Initial",
      type: "survey",
      formType: "postchat",
      header: "Thank you for chatting with us!",
      inputs: [{
        id: "VACU_PostChat_Q1",
        type: "select",
        dataErrorMesssage: "Please enter a valid name",
        isRequired: true,
        label: "1. Was chat able to answer your question?",
        options: ["Yes", "No"],
        placeholder: "Please select...",
        requiredErrorMesssage: "Please enter a valid name",
        size: "flex-100"
      }, {
        id: "VACU_PostChat_Q2",
        type: "select",
        dataErrorMesssage: "Please enter a valid name",
        isRequired: true,
        label: "2. Was chat able to save you a trip to the branch or a phone call?",
        options: ["Yes", "No"],
        placeholder: "Please select...",
        requiredErrorMesssage: "Please enter a valid name",
        size: "flex-100"
      }, {
        id: "VACU_PostChat_Q3",
        type: "select",
        dataErrorMesssage: "Please enter a valid name",
        isRequired: true,
        label: "3. How satisfied were you with the chat experience? ",
        options: ["5 - Very satisfied", "4", "3", "2", "1 - Very dissatisfied"],
        placeholder: "Please select...",
        requiredErrorMesssage: "Please enter a valid name",
        size: "flex-100"
      }, {
        id: "VACU_PostChat_Q4",
        type: "textarea",
        label: "4. We would appreciate your thoughts on how we can improve your experience:",
        size: "flex-100"
      }],
      onEntry: function onEntry() {
        api.hideLayer("btnMinimize");
      },
      subheader: "Please take a moment to complete the following brief survey.",
      submit_btn: "Submit",
      surveyAlerts: [{
        name: "VAC-PostChat-Survey-Spec-1",
        conditions: {
          and: ["VACU_PostChat_Q3 equals 1 - Very dissatisfied"]
        },
        emailSpecName: "VAC-PostChat-Survey-Spec-1",
        emailSubject: "Chat Survey Response Alert!",
        emailTemplate: "template::2003"
      }],
      template: "template::2001",
      transitions: {
        next: [{
          target: "node::2002"
        }]
      }
    }
  };
});